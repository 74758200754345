<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="类型" prop="type" class="fullList">
          <el-radio-group v-model="type" :disabled="disabled">
            <el-radio
              v-for="(type, index) in typeList"
              :key="index"
              :label="index"
            >
              {{ type }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上级公司名称" prop="parentId" v-if="type === 1">
          <el-select
            v-model="dataForm.parentId"
            :disabled="disabled"
            placeholder="上级公司名称"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="社会统一信用代码" prop="creditCode">
          <el-input
            v-model="dataForm.creditCode"
            :disabled="disabled"
            placeholder="社会统一信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input
            v-model="dataForm.contact"
            :disabled="disabled"
            placeholder="联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人电话" prop="contactMobile">
          <el-input
            v-model="dataForm.contactMobile"
            :disabled="disabled"
            placeholder="联系人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模" prop="companySizeId">
          <el-select
            v-model="dataForm.companySizeId"
            :disabled="disabled"
            placeholder="公司规模"
          >
            <el-option
              v-for="(item, index) in companySizeList"
              :key="index"
              :label="item.size"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否记账" prop="isMark">
          <el-radio-group v-model="dataForm.isMark" :disabled="disabled">
            <el-radio :label="0">不记账</el-radio>
            <el-radio :label="1">记账</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="折扣率" prop="discountRate">
          <el-input
            v-model="dataForm.discountRate"
            :disabled="disabled"
            placeholder="输入98代表98%"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="address" class="fullList">
          <el-input
            v-model="dataForm.address"
            :disabled="disabled"
            placeholder="具体到楼层"
            @change="tranAddress"
          />
          <div class="mapBlock">
            <el-button @click="openForm" type="info" v-if="!chooseVisible"
              >打开地图</el-button
            >
            <el-button @click="closeForm" type="warning" v-else
              >关闭地图</el-button
            >
            <map-choose
              ref="mapchoose"
              v-if="chooseVisible"
              @newAddress="getNewAddress"
              class="mapWay"
            />
          </div>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getCompanyList, getCompanySizeList } from '@/utils/options.js';
import { normal } from '@/mixins';
import mapChoose from '@/components/addressChoose';
import { keys, pick } from 'lodash';
export default {
  mixins: [normal],
  components: {
    mapChoose,
  },
  data() {
    return {
      disabled: false,
      visible: false,
      chooseVisible: false,
      markersLayer: null,
      geometries: null,
      dataForm: {
        id: 0,
        parentId: '',
        name: '',
        parentCompanyName: '',
        address: '',
        creditCode: '',
        contact: '',
        contactMobile: '',
        companySizeId: '',
        isMark: 0,
        discountRate: '',
        leftMoney: '',
        list: [],
        longitude: null,
        latitude: null,
      },
      type: 0,
      typeList: ['总公司', '分公司'],
      companyList: [],
      companySizeList: [],
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        parentId: [
          { required: true, message: '上级公司名称不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getCompanyList();
    this.getCompanySizeList();
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.type = 0;
      this.visible = true;
      this.dataForm.parentId = '';
      this.openForm();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/company/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.company, keys(this.dataForm));
              if (
                this.dataForm.parentId !== 0 &&
                this.dataForm.parentId !== null
              ) {
                this.type = 1;
              }
            }
          });
        }
      });
    },
    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getCompanySizeList() {
      getCompanySizeList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companySizeList = data.list;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.type === 0) {
        this.dataForm.parentId = 0;
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/company/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            }
          });
        }
      });
    },
    openForm() {
      this.chooseVisible = true;
      this.$nextTick(() => {
        if (this.dataForm.address) {
          this.$refs.mapchoose.getAddress(this.dataForm.address);
        } else {
          this.$refs.mapchoose.getAddress('佛山市');
        }
      });
    },
    closeForm() {
      this.chooseVisible = false;
    },
    getNewAddress(address) {
      this.dataForm.address = address;
      this.tranAddress(address);
    },
    async tranAddress(data) {
      if (this.chooseVisible) {
        const address = await this.$refs.mapchoose.getAddress(data);
        if (address !== null) {
          this.dataForm.latitude = address.latitude;
          this.dataForm.longitude = address.longitude;
        }
      }
    },
  },
};
</script>
